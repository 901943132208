import { RootState } from '@/redux/store';

export const getThemeColor = (state: RootState) => state.settings.themeColor;
export const getScreenMode = (state: RootState) => state.settings.screenMode;
export const getTitleSize = (state: RootState) =>
  state.settings.distanceNumber.title;
export const getDescriptionSize = (state: RootState) =>
  state.settings.distanceNumber.description;
export const getShowVideo = (state: RootState) => state.settings.showVideo;
export const getSideBarOpen = (state: RootState) => state.settings.sideBarOpen;
