import { useEffect, useRef, useState } from 'react';
import { Channel, Socket } from 'phoenix';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from '@/redux/hooks';
import { setChannelConnectionStatus } from '@/redux/slices/auth';
import { connectionErrorData } from '@/components/Sidebar/helpers';
import { ConnectionErrorInfo } from '@/ts/interfaces/auth';

export type PhoenixChannel = Channel | null;
export type PhoenixSocket = Socket | null;
export type ConnectionError = ConnectionErrorInfo | null;

interface PhoenixChannelHook {
  channel: PhoenixChannel;
  socket: PhoenixSocket;
  error: ConnectionError;
}

let channelInstance;
let socketInstance;

const usePhoenixChannel = (uuid: string): PhoenixChannelHook => {
  const [channel, setChannel] = useState<PhoenixChannel>(null);
  const [socket, setSocket] = useState<PhoenixSocket>(null);
  const [error, setError] = useState<ConnectionError>(null);
  const uuidRef = useRef(uuidv4());

  const dispatch = useAppDispatch();

  const handleSocketError = () => {
    setError(connectionErrorData.socket);
  };

  const handleChannelError = () => {
    setError(connectionErrorData.channel);
  };

  useEffect(() => {
    if (socket) {
      socketInstance = socket;
    } else {
      socketInstance = new Socket('wss://btwb-ex-tv-cnc-prod.fly.dev/socket', {
        params: { uuid: uuidRef.current },
        // logger: (kind, msg, data) => {
        //   console.log(`${kind}: ${msg}`, data);
        // },
      });
    }

    socketInstance.connect();
    socketInstance.onError(handleSocketError);
    socketInstance.onOpen(function () {
      console.info('Socket was opened');
      setError(null);
    });

    if (uuid) {
      if (channel) {
        channelInstance = channel;
      } else {
        channelInstance = socketInstance.channel(`tv_control:${uuid}`);
      }
      channelInstance
        .join()
        .receive('ok', () => {
          dispatch(setChannelConnectionStatus(true));
          setError(null);
        })
        .receive('error', ({ reason }) => {
          console.error('Failed to join channel', reason);
          handleChannelError();
        });
      channelInstance.onError(handleChannelError);
      channelInstance.onClose(handleChannelError);

      setChannel(channelInstance);
      setSocket(socketInstance);
    }

    return () => {
      channel?.leave();
      socket?.disconnect();
    };
  }, [uuid]);

  return { channel, socket, error };
};

export default usePhoenixChannel;
