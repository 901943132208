import { ColorOption, ScreenOption } from '@/ts/interfaces/settings';
import { ConnectionErrorInfo } from '@/ts/interfaces/auth';

export const themeColorValues: ColorOption[] = [
  {
    label: 'Orange',
    value: ['#E84924'],
  },
  {
    label: 'Purple',
    value: ['#7257E0'],
  },
  {
    label: 'Magento',
    value: ['#28336B'],
  },
  {
    label: 'Lime',
    value: ['#BBE137'],
  },
  {
    label: 'Winterfresh',
    value: ['#ADD5D0', '#344E4F'],
  },
  {
    label: 'Winterfresh-Dark',
    value: ['#2A2A2A', '#ADD5D0'],
  },
  {
    label: 'DC America',
    value: ['#732255', '#EEEEEE'],
  },
];

export const screenModeOptions: ScreenOption[] = [
  { value: 'Workout', path: '/workout' },
  { value: 'Check-In', path: '/check-in' },
];
export const connectionErrorData: Record<string, ConnectionErrorInfo> = {
  socket: {
    title: 'Failure to connect to websocket',
    message:
      'Unable to display QR code. Relaunch btwb.tv to see if this resolves the issue.',
  },
  channel: {
    title: 'Websocket connection but with additional errors',
    message:
      'Unable to display QR code. Relaunch btwb.tv to see if this resolves the issue.',
  },
};
