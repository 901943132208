import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CheckInState,
  ClassDetails,
  ClassRecord,
  Location,
} from '@/ts/interfaces/check-in';

const initialState: CheckInState = {
  selectedClass: '',
  selectedLocation: '',
  locations: [],
  classes: [],
  classDetails: {},
};

export const checkIn = createSlice({
  name: 'check-in',
  initialState,
  reducers: {
    updateClass: (state, action: PayloadAction<string>) => {
      state.selectedClass = action.payload;
    },
    updateLocation: (state, action: PayloadAction<string>) => {
      state.selectedLocation = action.payload;
    },
    setLocations: (state, action: PayloadAction<Location[]>) => {
      state.locations = action.payload;
    },
    setClasses: (state, action: PayloadAction<ClassRecord[]>) => {
      state.classes = action.payload;
    },
    setClassDetails: (state, action: PayloadAction<ClassDetails>) => {
      state.classDetails = action.payload;
    },
  },
});

export const {
  updateClass,
  setLocations,
  setClasses,
  setClassDetails,
  updateLocation,
} = checkIn.actions;

export default checkIn.reducer;
