import { RootState } from '@/redux/store';

export const getGym = (state: RootState) => state.auth.gym;
export const getAccessibilityStatus = (state: RootState) =>
  state.auth.isContentAccessible;
export const getTokenStatus = (state: RootState) => state.auth.isTokenUpdated;
export const getTVDataFetchedStatus = (state: RootState) =>
  state.auth.isTVDataFetched;
export const getOrganizations = (state: RootState) => state.auth.organizations;
export const getOrganizationId = (state: RootState) =>
  state.auth.organizationId;
export const getDeviceCodeInfo = (state: RootState) =>
  state.auth.deviceCodeInfo;
export const getLastUsedOrganizationId = (state: RootState) =>
  state.auth.lastUsedOrganizationId;
export const getSignOutFlowStatus = (state: RootState) =>
  state.auth.isSignOutFlow;
export const getDisconnectStatus = (state: RootState) =>
  state.auth.disconnectInitiated;
export const getInstanceUUID = (state: RootState) => state.auth.instanceUUID;
export const getChannelConnectionStatus = (state: RootState) =>
  state.auth.isChannelConnected;
export const getRemoteUUID = (state: RootState) => state.auth.remoteUUID;
