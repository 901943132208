import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUpdatedWods } from '@/libs/utils/functions/workout';
import { Track, Workout, WorkoutState } from '@/ts/interfaces/workout';

const initialState: WorkoutState = {
  tracksLoaded: false,
  workouts: [],
  tracks: [],
  selectedWods: [],
  lastUpdatedTime: null,
};

export const workout = createSlice({
  name: 'workout',
  initialState,
  reducers: {
    setTrackData: (
      state,
      action: PayloadAction<{
        tracks: Track[];
        workouts: Workout[];
        selectedWods: Workout[];
      }>
    ) => {
      const { tracks, workouts, selectedWods } = action.payload;
      state.tracks = tracks;
      state.workouts = workouts;
      state.selectedWods = selectedWods;
      state.tracksLoaded = true;
    },
    updateSelectedWods: (state, action: PayloadAction<{ wods: Workout }>) => {
      const { wods } = action.payload;
      const tracksCopy = [...state.tracks];
      const selectedWodsCopy = [...state.selectedWods];
      const { tracks, selectedWods } = getUpdatedWods(
        wods,
        tracksCopy,
        selectedWodsCopy
      );
      state.tracks = tracks;
      state.selectedWods = selectedWods;
    },
    updateTime: (state, action: PayloadAction<string>) => {
      state.lastUpdatedTime = action.payload;
    },
  },
});

export const { updateSelectedWods, setTrackData, updateTime } = workout.actions;

export default workout.reducer;
