import { useEffect, useRef } from 'react';

const useInactivityTimeout = (
  callback: () => void,
  delay: number,
  isActive: boolean
) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (isActive) {
      timeoutRef.current = setTimeout(callback, delay);
    }
  };

  useEffect(() => {
    if (!isActive) return;
    const events = ['click', 'keydown', 'mousemove', 'touchstart', 'touchmove'];

    resetTimeout();
    events.forEach((event) => window.addEventListener(event, resetTimeout));

    return () => {
      events.forEach((event) =>
        window.removeEventListener(event, resetTimeout)
      );

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isActive]);
};

export default useInactivityTimeout;
