import { useState, useEffect } from 'react';

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    const determineDeviceType = () => {
      const width = window.innerWidth;
      setDeviceType({
        isMobile: width <= 1023,
        isTablet: width >= 1024 && width <= 1366,
        isDesktop: width > 1366,
      });
    };
    determineDeviceType();
    window.addEventListener('resize', determineDeviceType);

    return () => {
      window.removeEventListener('resize', determineDeviceType);
    };
  }, []);

  return deviceType;
};

export default useDeviceType;
