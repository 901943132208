import moment from 'moment';
import { GET_LOCATIONS } from '@/graphql/queries';
import { setLocations } from '@/redux/slices/check-in';
import { FetchLocations } from '@/ts/types';
import { Location } from '@/ts/interfaces/check-in';

export const fetchLocations: FetchLocations = async (
  client,
  dispatch,
  organizationId
) => {
  const token = localStorage.getItem('token');

  try {
    const { data } = await client.query({
      context: {
        headers: {
          'x-hasura-role': 'cap_screen_display_organization',
          'x-hasura-org-id': organizationId,
          authorization: `Bearer ${token}`,
        },
      },
      variables: {
        organizationId: organizationId,
        date: moment().format('YYYY-MM-DD'),
      },
      query: GET_LOCATIONS,
    });

    const filteredLocations = data?.stringer_ro?.location?.filter(
      (location: Location) => location?.class_records?.length > 0
    );

    dispatch(setLocations(filteredLocations));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
