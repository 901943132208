import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useSession } from 'next-auth/react';
import { usePathname, useRouter } from 'next/navigation';
import { useApolloClient, useQuery } from '@apollo/client';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import {
  getTracks,
  getSelectedWods,
  getLastUpdatedTime,
  getTracksLoadedStatus,
} from '@/redux/slices/workout/selectors';
import {
  getTokenStatus,
  getOrganizationId,
  getDeviceCodeInfo,
  getSignOutFlowStatus,
  getAccessibilityStatus,
  getLastUsedOrganizationId,
  getInstanceUUID,
} from '@/redux/slices/auth/selectors';
import { getScreenMode } from '@/redux/slices/settings/selectors';
import { getSelectedClass } from '@/redux/slices/check-in/selectors';
import { updateScreenMode } from '@/redux/slices/settings';
import { setClassDetails } from '@/redux/slices/check-in';
import {
  setInstanceUUID,
  setTVDataFetched,
  updateSignOutFlowStatus,
} from '@/redux/slices/auth';
import { GET_CLASS_DETAILS } from '@/graphql/queries';
import { useDeviceType } from '@/libs/utils/hooks';
import {
  exchangeToken,
  fetchDeviceInfo,
  fetchOrganizationsInfo,
  handleGymId,
  refreshDeviceFlowToken,
} from '@/libs/utils/functions/auth';
import { getTrack } from '@/libs/utils/functions/workout';
import { fetchLocations } from '@/libs/utils/functions/check-in';
import { DeviceFlowToken, SessionResult } from '@/ts/interfaces/auth';

const useInitApp = () => {
  const { data: session, status } = useSession() as SessionResult;
  const [orgInfoFetched, setOrgInfoFetched] = useState(false);
  const [deviceInfoFetched, setDeviceInfoFetched] = useState(false);
  const [deviceFlowToken, setDeviceFlowToken] =
    useState<DeviceFlowToken | null>(
      JSON.parse(localStorage.getItem('deviceFlowToken') || 'null')
    );

  const pathname = usePathname();
  const router = useRouter();
  const { isMobile } = useDeviceType();
  const client = useApolloClient();
  const dispatch = useAppDispatch();

  const screenMode = useAppSelector(getScreenMode);
  const tracksFromStore = useAppSelector(getTracks);
  const instanceUUID = useAppSelector(getInstanceUUID);
  const isTokenUpdated = useAppSelector(getTokenStatus);
  const organizationId = useAppSelector(getOrganizationId);
  const selectedClassId = useAppSelector(getSelectedClass);
  const tracksLoaded = useAppSelector(getTracksLoadedStatus);
  const isSignOutFlow = useAppSelector(getSignOutFlowStatus);
  const lastUpdatedTime = useAppSelector(getLastUpdatedTime);
  const selectedWodsFromStore = useAppSelector(getSelectedWods);
  const isContentAccessible = useAppSelector(getAccessibilityStatus);
  const deviceCodeInfo = useAppSelector(getDeviceCodeInfo);
  const lastUsedOrganizationId = useAppSelector(getLastUsedOrganizationId);
  const token = localStorage.getItem('token');

  const isRemoteControlMode = pathname === '/remote';
  const trackEventIds =
    new URLSearchParams(window.location.search)
      .get('track_event_ids')
      ?.split(',') || [];

  const { data, loading, error } = useQuery(GET_CLASS_DETAILS, {
    variables: { id: selectedClassId },
    context: {
      headers: {
        'x-hasura-role': 'cap_screen_display_organization',
        'x-hasura-org-id': organizationId,
        authorization: `Bearer ${token}`,
      },
    },
    skip: !selectedClassId || screenMode === 'Workout',
    pollInterval: 5000,
  });

  useEffect(() => {
    const fetchData = async () => {
      if (isRemoteControlMode) return;
      !instanceUUID && dispatch(setInstanceUUID(uuidv4()));
      if (
        status === 'unauthenticated' &&
        pathname !== '/autosignin' &&
        !deviceInfoFetched &&
        !deviceFlowToken?.access_token
      ) {
        await fetchDeviceInfo(
          dispatch,
          setDeviceInfoFetched,
          setDeviceFlowToken
        );
      }
      if (status === 'authenticated' || !!deviceFlowToken?.access_token) {
        const currentToken =
          session?.accessToken || deviceFlowToken?.access_token;

        if (!orgInfoFetched) {
          localStorage.setItem('jwt', currentToken!);
          await fetchOrganizationsInfo(client, dispatch, setOrgInfoFetched);
        }
        if (isContentAccessible && tracksLoaded) {
          dispatch(updateScreenMode('Workout'));
          dispatch(updateSignOutFlowStatus(false));
          router.push('/workout');
        } else if (!isContentAccessible) {
          router.push('/no-content');
        }
      }
      if (deviceCodeInfo && !deviceFlowToken?.access_token) {
        router.push('/sign-in');
      }
    };

    if (isMobile && !isRemoteControlMode) {
      router.push('unsupported-device');
    } else {
      fetchData();
    }
  }, [
    status,
    isContentAccessible,
    tracksLoaded,
    deviceCodeInfo,
    deviceFlowToken,
    isMobile,
  ]);

  useEffect(() => {
    if (isRemoteControlMode) return;
    if (organizationId) {
      exchangeToken(client, dispatch, organizationId).then(() => {
        handleGymId(client, dispatch, organizationId);
        getTrack(
          trackEventIds,
          client,
          dispatch,
          lastUpdatedTime,
          organizationId,
          lastUsedOrganizationId,
          {
            tracksFromStore,
            selectedWodsFromStore,
          }
        );
        dispatch(setTVDataFetched(true));
      });
    }
  }, [organizationId]);

  useEffect(() => {
    if (isRemoteControlMode) return;
    if (isTokenUpdated) {
      fetchLocations(client, dispatch, organizationId);
    }
  }, [organizationId, isTokenUpdated]);

  useEffect(() => {
    if (isRemoteControlMode) return;
    if (isSignOutFlow) {
      setDeviceFlowToken(null);
      setDeviceInfoFetched(false);
    }
  }, [isSignOutFlow]);

  useEffect(() => {
    if (isRemoteControlMode) return;
    if (!loading && data) {
      dispatch(setClassDetails(data?.stringer_ro?.class_record[0]));
    }
    if (error) {
      console.error('Error fetching class details:', error);
    }
  }, [loading, data, error]);

  useEffect(() => {
    if (isRemoteControlMode) return;
    if (deviceFlowToken) {
      const tokenExpiryTime =
        (deviceFlowToken.created_at + deviceFlowToken.expires_in) * 1000;
      const timeToExpiry = tokenExpiryTime - Date.now() - 5 * 60 * 1000;

      if (timeToExpiry > 0) {
        const refreshTimeout = setTimeout(async () => {
          await refreshDeviceFlowToken(setDeviceFlowToken);
        }, timeToExpiry);

        return () => clearTimeout(refreshTimeout);
      }
    }
  }, [deviceFlowToken]);
};

export default useInitApp;
