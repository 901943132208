import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingsState } from '@/ts/interfaces/settings';

const initialState: SettingsState = {
  themeColor: '#E84924',
  screenMode: 'Workout',
  sideBarOpen: true,
  showVideo: true,
  distanceNumber: { title: 4, description: 4 },
};

export const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    updateThemeColor: (state, action: PayloadAction<string>) => {
      state.themeColor = action.payload;
    },
    updateScreenMode: (state, action: PayloadAction<string>) => {
      state.screenMode = action.payload;
    },
    updateTitleSize: (state, action: PayloadAction<number>) => {
      state.distanceNumber.title = action.payload;
    },
    updateDescriptionSize: (state, action: PayloadAction<number>) => {
      state.distanceNumber.description = action.payload;
    },
    updateVideoAppearance: (state, action: PayloadAction<boolean>) => {
      state.showVideo = action.payload;
    },
    updateSidebarAppearance: (state, action: PayloadAction<boolean>) => {
      state.sideBarOpen = action.payload;
    },
  },
});

export const {
  updateThemeColor,
  updateScreenMode,
  updateVideoAppearance,
  updateTitleSize,
  updateDescriptionSize,
  updateSidebarAppearance,
} = settings.actions;

export default settings.reducer;
